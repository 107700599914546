import React, { useEffect, useState } from 'react';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { faSearch, faTh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import SEO from '../../components/Seo';
import Button from '../common/Button';
import ContentfulContent from '../common/ContentfulContent';
import Layout from '../Layout';
import './../layout.css';
import './LayoutCommon.css';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import ResourceOptInModal from '../common/ResourceOptInModal';
import Card from '../common/Card';

interface ResourceContentProps {
  pageData: any;
}

const ResourceContent = (props: ResourceContentProps) => {
  return (
    <>
      <div
        className="rounded-t-lg h-96 w-full bg-cover"
        style={{
          backgroundImage: props.pageData?.image
            ? `url(${props.pageData?.image?.fluid?.src})`
            : ''
        }}
      ></div>
      <section className="bg-white rounded-lg shadow-md p-4 sm:p-12">
        <h1 className="font-bold text-3xl text-gray-800">
          {props.pageData?.title}
        </h1>
        <section className="mt-10 content-body">
          {props.pageData && (
            <ContentfulContent content={props.pageData.body.body} />
          )}
        </section>
      </section>
    </>
  );
};

interface FeaturedResourceProps {
  collectionSlug?: string;
  resource: {
    id?: string;
    name?: string;
    title?: string;
    videoHost?: string;
    downloadLink: string;
    format: string;
    isPublicVideo?: boolean;
    optInRequired?: boolean;
  };
}

const OptInModal = ({ onSubmissionComplete, onClose, resource }: any) => {
  return (
    <ResourceOptInModal
      title="Before You Download"
      body="Please add your information"
      onSubmissionComplete={onSubmissionComplete}
      onClose={onClose}
      resource={resource}
    />
  );
};

const FeaturedResource: React.FC<FeaturedResourceProps> = props => {
  const [requiresOptIn] = useState(true);
  const [intendedDownload, setIntendedDownload] = useState();
  const [showOptIn, setShowOptIn] = useState(false);

  return (
    <>
      <div className="text-gray-700 py-4 border-b">
        {props.resource.isPublicVideo ? (
          <Link
            to={`/resources/video-collection/${props.collectionSlug}?selectedVideo=${props.resource.id}`}
          >
            <div className="flex items-center mb-2 text-primary">
              <FontAwesomeIcon
                className="mr-4"
                icon={
                  props.resource.format === 'Video' || props.resource.videoHost
                    ? faYoutube
                    : faFilePdf
                }
              />
              <p className="font-semibold sans-serif hover:underline">
                {props.resource.name || props.resource.title}
              </p>
            </div>
          </Link>
        ) : (
          <a
            className="cursor-pointer"
            onClick={() => {
              if (props.resource.optInRequired) {
                setIntendedDownload(props.resource);
                setShowOptIn(true);
              } else {
                window.open(props.resource.downloadLink, '_blank');
              }
            }}
          >
            <div className="flex items-center mb-2 text-primary">
              <FontAwesomeIcon
                className="mr-4"
                icon={
                  props.resource.format === 'Video' || props.resource.videoHost
                    ? faYoutube
                    : faFilePdf
                }
              />
              <p className="font-semibold sans-serif hover:underline">
                {props.resource.name || props.resource.title}
              </p>
            </div>
          </a>
        )}
      </div>
      {showOptIn ? (
        <OptInModal
          onSubmissionComplete={() => {
            setShowOptIn(false);
            window.open(intendedDownload.downloadLink, '_blank');
          }}
          onClose={() => {
            setShowOptIn(false);
          }}
          resource={intendedDownload}
        />
      ) : null}
    </>
  );
};

type PadletLink = {
  id: string;
  title: string;
  description: string;
  image: string;
  link: string;
};

interface PadletLinkProps {
  padletLink: PadletLink;
}

const PadletLink: React.FC<PadletLinkProps> = props => {
  return (
    <div className="text-gray-700 py-4 border-b ">
      <a href={props.padletLink.link} target="_blank">
        <div className="flex items-center mb-2 text-primary">
          <FontAwesomeIcon className="mr-2" icon={faTh} />
          <p className="font-semibold sans-serif hover:underline">
            {props.padletLink.title}
          </p>
        </div>
      </a>
    </div>
  );
};

const ResourceLayout = ({ pageContext }: any) => {
  return (
    <Layout>
      <SEO title={pageContext?.pageData?.title} />
      <div className="container mx-auto">
        <div className="bg-gray-50 p-4 sm:p-8 flex flex-col lg:flex-row">
          <div className="w-full lg:w-2/3 h-full mr-4">
            <ResourceContent pageData={pageContext?.pageData} />
            {/* <div className="mt-6">
              {pageContext?.pageData?.resourceGroupings ? (
                <>
                  {pageContext?.pageData?.resourceGroupings.map(g => (
                    <Card className="mb-4">
                      <h2 className="text-primary text-lg font-bold">
                        {g.title}
                      </h2>
                      <ul>
                        {g.resources.map((r: any, i: number) => (
                          <li key={i} className="mt-2">
                            <Link
                              to={r.downloadLink}
                              target="_blank"
                              className="text-gray-700 sans-serif hover:text-primary hover:underline cursor-pointer"
                            >
                              <span className="">
                                <FontAwesomeIcon
                                  className="mr-4"
                                  icon={
                                    r.format === 'Video' || r.videoHost
                                      ? faYoutube
                                      : faFilePdf
                                  }
                                />
                              </span>
                              {r.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </Card>
                  ))}
                </>
              ) : null}
            </div> */}
          </div>
          <div className="flex flex-col w-full lg:w-1/3 h-full lg:ml-4">
            <>
              {pageContext?.pageData?.resourceCollections?.map(
                (c: any, i: number) => (
                  <div key={i} className="mb-2">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <div className="bg-white rounded-lg shadow-md p-4 mt-4 lg:mt-0">
                            <Disclosure.Button className="flex hover:bg-gray-50 rounded px-3 py-2 focus:outline-none">
                              <span className="text-lg text-left font-bold text-primary hover:underline sans-serif">
                                {c.name}
                              </span>
                              <ChevronUpIcon
                                className={`${
                                  open ? 'transform rotate-180' : ''
                                } w-6 h-6 text-gray-500 my-auto`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel>
                              <div className="p-4">
                                {c.items &&
                                  c.items.map((r: any, i: number) => (
                                    <FeaturedResource
                                      resource={{
                                        ...r,
                                        isPublicVideo: !!r.videoHost
                                      }}
                                      key={i}
                                      collectionSlug={c.slug}
                                    />
                                  ))}
                              </div>
                            </Disclosure.Panel>
                          </div>
                        </>
                      )}
                    </Disclosure>
                  </div>
                )
              )}
            </>
            {pageContext?.pageData?.featuredResources?.length && (
              <div className="my-4 lg:mb-8">
                <aside className="bg-white rounded-lg shadow-md p-8 mt-4 lg:mt-0">
                  <p className="text-2xl font-bold text-gray-600 sans-serif mb-4">
                    Featured {pageContext?.pageData?.type}
                  </p>
                  <>
                    {pageContext?.pageData?.featuredResources?.map(
                      (r: any, i: number) => (
                        <FeaturedResource resource={r} key={i} />
                      )
                    )}
                  </>
                  {/* <>
                    {pageContext?.pageData?.title !==
                      'Supports for Families' && (
                      <section className="mt-4">
                        <Link to="/resource-center">
                          <Button
                            text={`Explore ${pageContext?.pageData?.type}`}
                            icon={faSearch}
                          />
                        </Link>
                      </section>
                    )}
                  </> */}
                </aside>
              </div>
            )}
            {pageContext?.pageData?.padlets?.length && (
              <aside className="bg-white rounded-lg shadow-md p-8 lg:mt-0">
                <p className="text-2xl font-bold text-gray-600 sans-serif mb-4">
                  Collections
                </p>
                {pageContext.pageData.padlets.map(
                  (p: PadletLink, i: number) => (
                    <PadletLink padletLink={p} key={i} />
                  )
                )}
              </aside>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ResourceLayout;
