import { Dialog, Transition } from '@headlessui/react';
import { DocumentTextIcon } from '@heroicons/react/solid';
import { Form, Formik } from 'formik';
import React, { Fragment, useRef, useState } from 'react';
import { FormInput, Label, FormError } from './../common/FormInput';
import LoadingButton from './LoadingButton';
import Select from 'react-select';
import * as Yup from 'yup';

const ResourceOptInSchema = Yup.object().shape({
  firstName: Yup.string().required('First name required'),
  lastName: Yup.string().required('Last name required'),
  schoolEmail: Yup.string().required('School email required'),
  jobTitle: Yup.string().required('Job title required'),
  schoolName: Yup.string().required('School name required'),
  districtName: Yup.string().required('District name required'),
  city: Yup.string().required('City required'),
  state: Yup.string().required('State required')
});
interface ResourceOptInModalProps {
  title: string;
  body: string;
  onSubmissionComplete: () => void;
  onClose: () => void;
  resource: any;
}

const ResourceOptInModal = (props: ResourceOptInModalProps) => {
  const [open, setOpen] = useState(true);

  const [submitting, setSubmitting] = useState(false);

  const cancelButtonRef = useRef(null);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => {
          props.onClose();
          setOpen(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <Formik
                  validationSchema={ResourceOptInSchema}
                  initialValues={{
                    firstName: '',
                    lastName: '',
                    schoolEmail: '',
                    jobTitle: '',
                    schoolName: '',
                    districtName: '',
                    city: '',
                    state: '',
                    resourceName: props.resource.title
                  }}
                  onSubmit={async values => {
                    try {
                      setSubmitting(true);
                      const req = await fetch(
                        `${process.env.GATSBY_FUNCTIONS_PATH}/resourceOptIn`,
                        {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json'
                          },
                          body: JSON.stringify(values)
                        }
                      );

                      const res = await req.json();

                      setSubmitting(false);

                      localStorage.setItem('resource_opt_in_complete', 'true');

                      props.onSubmissionComplete();
                    } catch (err) {
                      console.log('the err', err);
                    }
                  }}
                >
                  {({ errors, touched, setFieldValue, handleChange }) => (
                    <Form>
                      <div>
                        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                          <DocumentTextIcon
                            className="h-6 w-6 text-primary"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:mt-5">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                          >
                            {props.title}
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-gray-500 sans-serif">
                              {props.body}
                            </p>
                          </div>
                          <div className="mt-2 text-left">
                            <div className="mb-4">
                              <div className="mb-2">
                                <Label text="First Name" />
                              </div>
                              <FormInput
                                ariaLabel="First Name"
                                name="firstName"
                                type="text"
                                placeholder="First Name"
                              />
                            </div>
                            <div className="mb-4">
                              <div className="mb-2">
                                <Label text="Last Name" />
                              </div>
                              <FormInput
                                ariaLabel="Last Name"
                                name="lastName"
                                type="text"
                                placeholder="Last Name"
                              />
                            </div>
                            <div className="mb-4">
                              <div className="mb-2">
                                <Label text="School Email" />
                              </div>
                              <FormInput
                                ariaLabel="School Email"
                                name="schoolEmail"
                                type="text"
                                placeholder="School Email"
                              />
                            </div>
                            <div className="mb-4">
                              <div className="mb-2">
                                <Label text="Job Title" />
                              </div>
                              <Select
                                options={[
                                  {
                                    label: 'Teacher',
                                    value: 'Teacher'
                                  },
                                  {
                                    label: 'Instructional Coach',
                                    value: 'Instructional Coach'
                                  },
                                  {
                                    label: 'Principal / Assistant Principal',
                                    value: 'Principal / Assistant Principal'
                                  },
                                  {
                                    label:
                                      'Superintendent / Assistant Superintendent',
                                    value:
                                      'Superintendent / Assistant Superintendent'
                                  },
                                  {
                                    label: 'Curriculum Director / Coordinator',
                                    value: 'Curriculum Director / Coordinator'
                                  },
                                  {
                                    label: 'Other',
                                    value: 'Other'
                                  }
                                ]}
                                onChange={({ value }) =>
                                  setFieldValue('jobTitle', value)
                                }
                                name="jobTitle"
                              />
                              {errors.jobTitle && touched.jobTitle && (
                                <FormError text={errors.jobTitle} />
                              )}
                            </div>
                            <div className="mb-4">
                              <div className="mb-2">
                                <Label text="School Name" />
                              </div>
                              <FormInput
                                ariaLabel="School Name"
                                name="schoolName"
                                type="text"
                                placeholder="School Name"
                              />
                            </div>
                            <div className="mb-4">
                              <div className="mb-2">
                                <Label text="District Name" />
                              </div>
                              <FormInput
                                ariaLabel="District Name"
                                name="districtName"
                                type="text"
                                placeholder="District Name"
                              />
                            </div>
                            <div className="mb-4">
                              <div className="mb-2">
                                <Label text="City" />
                              </div>
                              <FormInput
                                ariaLabel="City"
                                name="city"
                                type="text"
                                placeholder="City"
                              />
                            </div>
                            <div className="mb-4">
                              <div className="mb-2">
                                <Label text="State" />
                              </div>
                              <FormInput
                                ariaLabel="State"
                                name="state"
                                type="text"
                                placeholder="State"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-6 grid grid-flow-row-dense sm:gap-3">
                        <LoadingButton
                          type="submit"
                          isLoading={submitting}
                          text="Download Documents"
                        />
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-4 py-2 text-base font-medium text-red-600 hover:bg-gray-50 focus:outline-none sm:col-start-1 sm:mt-0 sm:text-sm sans-serif"
                          onClick={() => setOpen(false)}
                          ref={cancelButtonRef}
                        >
                          Cancel
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ResourceOptInModal;
